import { Layout as AntLayout } from 'antd'
import { Fragment, useMemo } from 'react'
import { ReactNode, FC } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import styled from 'styled-components'
import { useUI } from '../../context/ui'
import { Routes } from '../../routes/config'
import Header from './Header'
import Sidebar from './Sidebar'
import { adoptMarginToTheSidebar } from '../utils/styleHelpers'
import { useAuth } from '../../features/auth'
import GlobalLoader from '../../components/GlobalLoader'
import { SPACES } from '../../styles/spaces'
import NotVerifiedUserLabel from './NotVerifiedUserLabel'
import { Colors } from '../../styles/colors'

interface IProps {
	children: ReactNode | ReactNode[]
}

const routesWithoutLayout = [
	Routes.Auth,
	Routes.ResetPasswordSave,
	Routes.VerifyEmail,
	Routes.OrganizationsAcceptInvitation,
	Routes.GamificationAdmin,
]

const Layout: FC<IProps> = ({ children }) => {
	const { isSidebarOpen } = useUI()
	const { isAuthInitializing, user } = useAuth()
	const location = useLocation()

	const isCurrentRouteWithoutLayout = useMemo(() => {
		return routesWithoutLayout.some((route) => {
			const match = matchPath(location.pathname, {
				path: route,
				exact: true,
				strict: false,
			})
			return !!match
		})
	}, [location])

	if (isCurrentRouteWithoutLayout) {
		return <Fragment>{children}</Fragment>
	}

	if (isAuthInitializing) {
		return <GlobalLoader />
	}

	const showNotVerifiedLabel = user && !user.isVerified

	return (
		<LayoutWrapper>
			{showNotVerifiedLabel && <NotVerifiedUserLabel />}
			<Header />
			<Sidebar />
			<ContentLayout isSidebarOpen={isSidebarOpen} showNotVerifiedLabel={showNotVerifiedLabel}>
				{children}
			</ContentLayout>
			<Footer isSidebarOpen={isSidebarOpen}>Abstract.rs</Footer>
		</LayoutWrapper>
	)
}

const LayoutWrapper = styled.div`
	background-color: ${Colors.BackgroundGray};
`

const ContentLayout = styled(({ isSidebarOpen, showNotVerifiedLabel, ...rest }) => (
	<AntLayout.Content {...rest} />
))`
	min-height: ${(props) =>
		props.showNotVerifiedLabel ? 'calc(100vh - 174px)' : 'calc(100vh - 134px)'};
	padding: ${SPACES.DEFAULT} ${SPACES.LARGE};
	margin: ${(props) => (props.showNotVerifiedLabel ? '104px' : '64px')} 0 0
		${(props) => adoptMarginToTheSidebar(props.isSidebarOpen)};
	transition: margin 0.2s ease-out;
`

const Footer = styled(({ isSidebarOpen, ...rest }) => <AntLayout.Footer {...rest} />)`
	display: flex;
	justify-content: center;
	margin-left: ${(props) => adoptMarginToTheSidebar(props.isSidebarOpen)};
	transition: margin 0.2s ease-out;
`

export default Layout
