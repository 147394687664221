import { Achievement, Badge, LeakStatus } from 'blue-project-types'

const en = {
	personalCredentials: {
		title: 'Leaked credentials for {{email}}',
		subtitle: `	Change the password linked to this account as soon as possible,\n
    then confirm the change by clicking on the "Resolved" button.`,
		resolveButton: 'Resolved',
		leakStatus: {
			[LeakStatus.Deprecated]: 'Deprecated',
			[LeakStatus.Discovered]: 'Discovered',
			[LeakStatus.Resolved]: 'Resolved',
		},
		resolveLeakModal: {
			title: 'Confirm leak resolution',
			subtitle: `Are you sure you've changed your password?`,
		},
		leakResolving: {
			success: 'Leak resolved',
			error: 'Error during leak resolution',
		},
	},
	gamification: {
		header: '{{organizationName}} - Company Leaderboard',
		tabs: {
			leaderboard: 'LEADERBOARD',
			groups: 'EMPLOYEE GROUPS',
		},
		groups: {
			title: 'Group {{groupNumber}}',
			members: 'Members',
			rank: 'Rank: {{number}}',
			totalPoints: 'Total Points: {{number}}',
			totalAchievements: 'Total Achievements:',
			totalBadges: 'Total Badges:',
		},
		table: {
			rank: 'Rank',
			tier: 'Tier',
			name: 'Name',
			group: 'Group',
			achievements: 'Achievements Earned',
			badges: 'Badges Earned',
			score: 'Total Points',
		},
	},
}

export default en
