import { Router, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Suspense } from 'react'
import { QueryParamProvider } from 'use-query-params'
import { parse } from 'query-string'

import './App.less'

import routeHistory from './utils/history'
import { UIProvider } from './context/ui'
import { AuthProvider } from './features/auth/context'
import { Layout } from './layout'
import AppRoutes from './routes/AppRoutes'
import { setBaseUrl, setTenantId, setToken } from './utils/axios'
import { API_BASE_URL } from './config/urls'
import LocalStorage from './utils/LocalStorage'
import { IToken, TOKEN_LOCAL_STORAGE_KEY } from './features/auth'
import AssetDiscoveryProvider from './features/inventory/context/AssetDiscoveryProvider'
import PdfExportsRoutes from './features/pdf-exports/Routes'
import { isPdfExportRoute, isPublicRoute } from './utils/app-routing'
import DownloadablePDFsProvider from './context/pdf-exports/DownloadablePDFsProvider'
import PublicRoutes from './features/public/Routes'
import i18n from './translate/i18n'

const queryClient = new QueryClient({
	defaultOptions: { queries: { retry: 0, refetchOnWindowFocus: false } },
})

function App() {
	// Url & Token configuration
	setBaseUrl(API_BASE_URL)

	const token = LocalStorage.get(TOKEN_LOCAL_STORAGE_KEY) as IToken
	if (token) {
		setToken(token.accessToken)
	}

	// In case we have pdf exporter routes we render diff app
	if (isPdfExportRoute()) {
		const { token: pdfToken, tenantId, lang } = parse(window.location.search)

		if (lang) {
			i18n.changeLanguage(lang as string)
		}

		if (token) {
			setToken(token.accessToken)
		}

		if (pdfToken) {
			LocalStorage.set(TOKEN_LOCAL_STORAGE_KEY, pdfToken)
			setToken(pdfToken as string)
		}

		if (tenantId) {
			setTenantId(tenantId as string)
		}

		return (
			<Router history={routeHistory}>
				<QueryClientProvider client={queryClient}>
					<QueryParamProvider ReactRouterRoute={Route}>
						<AuthProvider>
							<Suspense fallback={null}>
								<PdfExportsRoutes token={pdfToken} />
							</Suspense>
						</AuthProvider>
					</QueryParamProvider>
				</QueryClientProvider>
			</Router>
		)
	}

	// In case we have public route
	if (isPublicRoute()) {
		const { lang } = parse(window.location.search)

		if (lang) {
			i18n.changeLanguage(lang as string)
		}

		return (
			<Router history={routeHistory}>
				<QueryClientProvider client={queryClient}>
					<QueryParamProvider ReactRouterRoute={Route}>
						<Suspense fallback={null}>
							<PublicRoutes />
						</Suspense>
					</QueryParamProvider>
				</QueryClientProvider>
			</Router>
		)
	}

	return (
		<Router history={routeHistory}>
			<QueryClientProvider client={queryClient}>
				<QueryParamProvider ReactRouterRoute={Route}>
					<AuthProvider>
						<UIProvider>
							<Layout>
								<Suspense fallback={null}>
									<AssetDiscoveryProvider>
										<DownloadablePDFsProvider>
											<AppRoutes />
										</DownloadablePDFsProvider>
									</AssetDiscoveryProvider>
								</Suspense>
							</Layout>
						</UIProvider>
					</AuthProvider>
					{process.env.REACT_APP_ENV === 'local' && <ReactQueryDevtools initialIsOpen={false} />}
				</QueryParamProvider>
			</QueryClientProvider>
		</Router>
	)
}

export default App
