import { IApiError, IGetPeopleSimulationsOverviewResponse } from 'blue-project-types'
import { useQuery, useQueryClient } from 'react-query'

import { fetchPeoplePhishingOverview, FETCH_PEOPLE_PHISHING_OVERVIEW_KEY } from '../../utils/api'

const useFetchPeoplePhishingOverview = () => {
	const queryClient = useQueryClient()
	const {
		data,
		isFetching,
		error: errorFetchingPeoplePhishingOverview,
	} = useQuery<IGetPeopleSimulationsOverviewResponse, IApiError>(
		FETCH_PEOPLE_PHISHING_OVERVIEW_KEY,
		() => fetchPeoplePhishingOverview(),
	)

	const refetchPeoplePhishingOverview = () => {
		queryClient.invalidateQueries([FETCH_PEOPLE_PHISHING_OVERVIEW_KEY])
	}

	return {
		errorFetchingPeoplePhishingOverview,
		peoplePhishingOverview: data,
		isFetchingPeoplePhishingOverview: isFetching,
		refetchPeoplePhishingOverview,
	}
}

export default useFetchPeoplePhishingOverview
