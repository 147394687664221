import { LeakStatus } from 'blue-project-types'

const sr = {
	personalCredentials: {
		title: 'Procureli kredencijali za {{email}}',
		subtitle: `Promenite lozinke povezane sa ovim nalogom što je pre moguće zatim potvrdite promenu kliknom na dugme "Izmenjeno"`,
		resolveButton: 'Izmenjeno',
		leakStatus: {
			[LeakStatus.Deprecated]: 'Zastarelo',
			[LeakStatus.Discovered]: 'Otkriveno',
			[LeakStatus.Resolved]: 'Rešeno',
		},
		resolveLeakModal: {
			title: 'Potvrdite da ste rešili curenje lozinke',
			subtitle: `Da li ste sigurni da ste izmenili lozinku?`,
		},
		leakResolving: {
			success: 'Curenje lozinke uspešno rešeno',
			error: 'Greška prilikom rešavanja curenja',
		},
	},
	gamification: {
		header: '{{organizationName}} - Tabela rezultata kompanije',
		tabs: {
			leaderboard: 'TABELA REZULTATA',
			groups: 'GRUPE ZAPOSLENIH',
		},
		groups: {
			title: 'Grupa {{groupNumber}}',
			members: 'Članovi',
			rank: 'Rang: {{number}}',
			totalPoints: 'Ukupno poena: {{number}}',
			totalAchievements: 'Ukupno dostignuća:',
			totalBadges: 'Ukupno bedževa:',
		},
		table: {
			rank: 'Rang',
			tier: 'Nivo',
			name: 'Ime',
			group: 'Grupa',
			achievements: 'Osvojena dostignuća',
			badges: 'Osvojeni bedževi',
			score: 'Ukupno poena',
		},
	},
	
}

export default sr
