import { Suggestion, SuggestionActionTimeFrame } from 'blue-project-types'

const en = {
	phishingExport: {
		title: 'Phishing pdf export - {{simulation}} for {{tenantName}} organization',
		overview: {
			overview: 'Overview',
			templates: 'Templates',
			simulationFunnel: 'Simulation funnel',
		},
		targets: {
			title: 'Targets',
			failedSimulations: 'Failed the simulation',
			failedDesc:
				'People who failed to respond appropriately to phishing simulation emails by clicking on malicious links or entering sensitive data.',
			reported: 'Reported phishing',
			reportedDesc: 'People who successfully identified and reported phishing simulation emails.',
			notReportedDesc:
				'People who did not report phishing simulation emails after encountering them.',
			notReported: 'Not reported',
			table: {
				firstName: 'First name',
				lastName: 'Last name',
				simulationsSent: 'Simulations sent',
				simulationsFailed: 'Simulations failed',
				simulationsPassed: 'Simulations passed',
			},
		},
	},
	courseOverviewExport: {
		title: 'Assignment pdf export - {{courseName}} for {{tenantName}} organization',
		overview: 'Overview',
		lessons: 'Lessons',
		learners: 'Learners',
		nonCompleted: 'Not completed',
		nonCompletedDescription: `Learners who have been invited to the course but haven't completed it by the designated deadline.`,
		passed: 'Passed the course',
		passedDescription: 'Learners who have successfully completed and passed the course.',
		failed: 'Failed the course',
		failedDescription: `Learners who have been invited to the course but haven't successfully completed it.`,
	},
	exportLanguageSelectionModal: {
		title: 'Export to pdf',
		emailLanguageSelectLabel: 'Pdf language',
		emailLanguageSelectPlaceholder: 'Choose language used in pdf',
		emailLanguageOptions: {
			en: 'English',
			sr: 'Serbian',
		},
	},
	SeifScoreExport: {
		title: 'Seif Score Report for {{tenantName}} organization',
		dateOfReport: 'Date of Report: {{reportDate}}',
		executiveSummary: 'Executive Summary',
		noSeifScoreAlert: {
			title: 'Seif Score Not Available',
			subtitle:
				'"The Seif score for this organization hasn’t been calculated or there was an error retrieving it. Please try again later or contact support for assistance.".',
		},
		keyAction: {
			title: 'Key actions taken',
			subtitle:
				'Prioritized actions for addressing critical vulnerabilities, improving training, and managing credential weaknesses.',
			courses: {
				title: 'Awareness Courses',
				description: 'New cybersecurity awareness Courses launched',
			},
			simulations: {
				title: 'Phishing Simulations',
				description: 'Phishing simulations launched towards employees',
			},
			nanoCampaigns: {
				title: 'Seif Nano Learning',
				description: 'Nano Learning campaigns launched',
			},
			leaks: {
				title: 'Solved Credential Leaks',
				description: 'Critical Credential Leaks resolved',
			},
		},
		securityActions: {
			title: 'Seif has conducted the following security actions:',
			subtitle:
				'These actions are automated to ensure swift responses to critical security threats',
			[Suggestion.NotifyPeopleAboutLeak]: {
				title: 'Notified Employees of Compromised Credentials',
				description:
					'Employees with compromised credentials were promptly alerted upon discovery, enabling them to take corrective actions and reduce security risks.',
			},
			[Suggestion.RecreateExpiredCourse]: {
				title: 'Reassigned Expired Security Courses',
				description:
					'Automatically recreated and reassigned security training to individuals who missed their completion deadlines, ensuring continuous security education.',
			},
			[Suggestion.RecreateFailedCourse]: {
				title: 'Reassigned Failed Courses',
				description:
					'Recreated and reassigned courses for learners who did not successfully complete their training, along with invitations to retake and improve their performance.',
			},
		},
		recommendedActions: {
			title: 'Recommended actions',
			subtitle:
				'Recommended actions required in order to maintain organization’s security and keep your sensitive data secure.',
			subtitleNoActions: 'All recommended actions have been completed.',
			list: {
				title: {
					[SuggestionActionTimeFrame.Immediate]: 'Immediate actions (Today)',
					[SuggestionActionTimeFrame.ShortTerm]: 'Short-term actions (Next 7 days)',
					[SuggestionActionTimeFrame.LongTerm]: 'Long-term actions (Next 30 days)',
				},
				subtitle: {
					[SuggestionActionTimeFrame.Immediate]:
						'Immediate action is required to maintain the organization’s security and keep your sensitive data secure.',
					[SuggestionActionTimeFrame.ShortTerm]:
						'Address these actions soon to keep your organization’s security in good standing.',
					[SuggestionActionTimeFrame.LongTerm]:
						'Plan these actions for long-term security improvements.',
				},
			},
			linkComponent: {},
		},
		trainingCampaignsOverview: {
			title: 'Training Campaigns overview',
			courses: {
				title: 'Courses Summary',
				subtitle:
					'Key metrics from recent Training Campaigns, including course completion, learner performance, and overall success rates.',
				completionRate: {
					title: 'Course Completion rate',
					subtitle: 'Percentage of learners that completed the course, regardless of the score.',
				},
				passingRate: {
					title: 'Course passing rate',
					subtitle: 'Passing rate of learners, compared to the total number of learners.',
				},
			},
			nanoQuiz: {
				title: 'Seif Nano Quiz summary',
				subtitle:
					'Key metrics of concluded Nano Learning cycles, showing learner engagement rates and passing rates.',
				engagementRate: {
					title: 'Seif Nano Engagement rate',
					subtitle:
						'Number of learners who correctly answered quiz questions out of all participants.',
				},
				passingRate: {
					title: 'Seif Nano Quiz rate',
					subtitle: 'Number of correct answers compared to the total number of responses.',
				},
			},
			simulations: {
				title: 'Phishing Simulation results',
				subtitle:
					'Phishing test outcomes, including attack frequency, email interaction rates, and failure percentages.',
				progress: {
					total: 'Total Attacks',
					clicked: 'Clicked',
					failed: 'Failed',
					opened: 'Opened',
				},
			},
		},
		credentialLeakMonitoring: {
			title: 'Credential Leak monitoring',
			subtitle:
				'Overview of detected credential leaks and the number of leaks sanitized through immediate action.',
			detected: 'Credential leaks detected',
			unresolved: 'Credential leaks unresolved',
			resolved: 'Credential leaks resolved',
			notification: 'Weak password detected,  requires change of policy.',
		},
		table: {
			title: 'Employee Performance overview',
			displayName: 'Name',
			email: 'Email',
			points: 'Points',
			groups: 'Groups',
			seifScore: 'Seif Score',
			peopleDetails: {
				leaks: {
					title: 'Credential Leaks',
					text1: 'A total of ',
					text2plural: 'credential leaks were found, with',
					text2singular: 'credential leak was found, with',
					text3: 'resolved and',
					text4: 'still active.',
					noLeaksText: 'No critical credential leaks were found.',
				},
				courses: {
					title: 'Assignemnts',
					text1: 'This person was invited to ',
					text2Singular: ' course, with a success rate of',
					text2Plural: ' courses, with a success rate of',
					text3: ', a failure rate of',
					text4: ', and',
					text5: ' unfinished. ',
					averageScore: 'The average score for completed courses is',
					basicCoursePassed: 'The basic course was successfully completed with ',
					basicCourseFailed: 'The basic course was failed with ',
					points: ' points.',
					basicCourseNotCompleted: 'The basic course was not completed.',
					failedLessons: 'The lessons this person failed are:',
					noCourses: 'No course invitations have been issued to users.',
				},
				nanoLearning: {
					title: 'Nano Learning',
					highEngageText1: 'This person actively participates in nano campaigns, engaging with',
					highEngageText2:
						'of nano content, lessons, and quizzes, which also positively impacts their score by earning additional points.',
					lowEngageText1:
						'This person has a low participation rate in nano campaigns, interacting with ',
					lowEngageText2:
						' of nano content, which also affects their score with negative points. There is room for improvement.',
					text1: 'This person answered ',
					text2: ' of quiz questions correctly and',
					text3: 'incorrectly.',
					nanoContentFailed: 'Nano content the user failed:',
					noNanoLearning: 'No Nano Learning campaigns have been distributed.',
				},
				phishing: {
					title: 'Phishing Simulations',
					text1:
						'This person successfully passed (reported all phishing emails in the given simulation) ',
					text2: 'of the total simulations.',
					textNotPassedRate:
						'This person did not pass (report all phishing emails in the given simulation) in any of the total simulations.',
					text3: ' They did not react to ',
					text4: 'of simulations—neither falling for them nor reporting them—while they failed ',
					text5: 'of simulations.',
					failedTemplates: ' The templates the user fell for are:',
					noPhishing: 'No phishing simulations have been conducted.',
				},
			},
		},
	},
}

export default en
